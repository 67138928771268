<template>
  <b-card
    v-if="Object.keys(blogEdit).length"
    class="blog-edit-wrapper"
  >
  <b-overlay
    :show="disabled"
    rounded="sm"
  >
    <!-- form -->
    <b-form class="mt-2">

      <b-row>
        <b-col md="6">
          <b-form-group
            label="Title"
            label-for="blog-edit-title"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-title"
              v-model="blogEdit.title"
              :disabled="disabled"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Category"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <v-select
              v-if="blogEdit.category"
              id="blog-edit-category"
              v-model="blogEdit.category"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :options="kbCategory"
              :disabled="disabled"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Subtitle"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="blogEdit.subtitle"
              :disabled="disabled"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Companies"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <v-select
              v-if="kbCompanies.length > 0"
              id="blog-edit-category"
              v-model="companies"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="kbCompanies"
              multiple
              :disabled="disabled"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Content"
            label-for="blog-content"
            class="mb-2"
          ><div class="document-editor">
          <ckeditor v-model="blogEdit.body" :editor="editor" :config="editorConfig" @ready="onReady"/>
        </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="blogEdit.lastModifiedBy = $auth.user.name; updateKBArticle();"
          >
            Save Changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="
              $router
                .push('/knowledge-base/' + categoryID + '/' + articleId)
                .catch(() => {})
            "
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import Editor from '@core/ckeditor5/build/ckeditor'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BOverlay,
    vSelect,
    ckeditor: CKEditor.component,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      editor: Editor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: { 
      },
      disabled: false,
      companies: [],
      articleId: '',
      categoryID: '',
      kbCategory: {},
      kbCompanies: {},
      blogEdit: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: {
          ID: 0,
          UID: '',
          timeModified: '',
          title: '',
          subtitle: '',
          category: '',
          customer: [{ identifier: '', name: '' }],
          author: '',
          body: '',
          body2: '',
          lastModifiedBy: '',
          kbCatID: '',
          pageView: 0,
        },
      },
    }
  },
  async created() {
    // this.$http.get('/blog/list/data/edit').then(res => { this.blogEdit = res.data })
    this.articleId = this.$route.params.id
    this.categoryID = this.$route.params.category
    this.disabled = true
    await this.$http
      .get(
        `https://asi-api2.azure-api.net/kb/article?id=${this.articleId}&action=editing&key=7d959c93d0ed474abcdf04804842848a`,
      )
      .then(res => {
        this.blogEdit = res.data.results
        this.companies = res.data.results.customer
      })
    await this.getKBCategories()
    

    this.disabled = false
  },
  methods: {
    onReady(editor) {
      
      // Insert the toolbar before the editable area.
      editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement(),
      )
    },
    async getKBCategories() {
      await this.$http
        .get(
          'https://asi-api2.azure-api.net/kb/categories?type=catOnly&key=7d959c93d0ed474abcdf04804842848a',
        )
        .then(response => {
          this.kbCategory = response.data.results.categories
          this.kbCompanies = response.data.results.companies
        })
        .catch(error => {
          console.log(error)
        })
    },
    updateKBArticle() {
      this.blogEdit.customer = this.companies
      this.$http
        .patch(
          'https://asi-api2.azure-api.net/kb/article?key=7d959c93d0ed474abcdf04804842848a',
          this.blogEdit,
        )
        .then(response => {
          this.blogEdit = response.data
          this.$router
            .push(`/knowledge-base/${this.categoryID}/${this.articleId}`)
            .catch(() => {})
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/pages/page-blog.scss";
</style>

<style lang="css">
.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: calc(
    100vh - 560px
  ); /* set the maximum height to the height of the viewport minus 100 pixels */

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}
.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

/* Adjust the look of the toolbar inside the container. */
.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}
</style>
